import 'babel-polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// Import the Auth0 configuration
import authConfig from '../auth_config';
import { setupAuth } from './auth';

const app = createApp(App).use(store).use(router);

function callbackRedirect(appState) {
  router.push(appState && appState.targetUrl ? appState.targetUrl : '/');
}
setupAuth(authConfig, callbackRedirect).then((auth) => {
  app.use(auth).mount('#app');
});
