<template>
  <span class="profile-nav">
    <span v-if="$auth.user">
      <router-link to="/profile">{{$auth.user.name}}</router-link> |
      <router-link to="/logout" @click="logout">Log Out</router-link>
    </span>
    <span v-else>
      <router-link to="/login" @click="login">Log In</router-link> |
      <router-link to="/login" @click="login">Register</router-link>
    </span>

  </span>
</template>

<script>
export default {
  name: 'ProfileNav',
  props: {
    msg: String,
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style scoped>

</style>
