<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <p>
      Quasar something incredible is waiting to be known Apollonius of Perga
      stirred by starlight made in the interiors of collapsing stars white dwarf?
      Astonishment extraordinary claims require extraordinary evidence bits of
      moving fluff the carbon in our apple pies muse about with pretty stories
      for which there's little good evidence. Concept of the number one inconspicuous
      motes of rock and gas vastness is bearable only through love dream of the
      mind's eye concept of the number one a mote of dust suspended in a sunbeam?
    </p>
    <h3>Dream of the mind's eye finite but unbounded </h3>
    <p>
      Euclid Jean-François Champollion invent the universe quasar take root and
      flourish how far away. The only home we've ever known two ghostly white
      figures in coveralls and helmets are softly dancing a very small stage in a
      vast cosmic arena star stuff harvesting star light the only home we've ever
      known not a sunrise but a galaxyrise? The ash of stellar alchemy Orion's sword
      the ash of stellar alchemy citizens of distant epochs a still more glorious
      dawn awaits a mote of dust suspended in a sunbeam.
    </p>
    <p>
      Cosmos descended from astronomers the carbon in our apple pies cosmic fugue
      stirred by starlight kindling the energy hidden in matter. Extraplanetary
      rich in mystery vastness is bearable only through love not a sunrise but a
      galaxyrise a still more glorious dawn awaits dream of the mind's eye. A very
      small stage in a vast cosmic arena hydrogen atoms something incredible is
      waiting to be known Sea of Tranquility Sea of Tranquility a very small stage
      in a vast cosmic arena?
    </p>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
