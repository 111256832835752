<template>
  <div id="app">
    <router-link to="/"><img id="logo-header" alt="Vue logo" src="./assets/logo.png"></router-link>
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <profile-nav></profile-nav>
    </div>
    <router-view/>
  </div>
</template>

<script>
import ProfileNav from './components/ProfileNav.vue';

export default {
  name: 'App',
  components: {
    ProfileNav,
  },
  methods: {
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
#logo-header {
  height: 32px;
}

</style>
